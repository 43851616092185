<script>
  import { fly } from "svelte/transition";
  import Icon from "./Icon.svelte";
  import Swal from "sweetalert2";

  let menuVisible = false;

  function toggleMenu(evt) {
    menuVisible = !menuVisible;
    evt.stopPropagation();
  }

  function closeMenu(evt) {
    menuVisible = false;
  }

  function openHelp() {
    Swal.fire({
      title: "Hjelp og support",
      html:
        '<div style="margin-bottom: 16px;">Hvis du lurer på noe eller trenger hjelp til å endre noe kan du ta kontakt på:</div> <a href="mailto:utengrenser@hennig-olsen.no" style="white-space: nowrap;">utengrenser@hennig-olsen.no</a>',
      type: "info",
      confirmButtonText: "Lukk"
    });
  }
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.profile {
  position: absolute;
  padding: 8px;
  padding: 8px;
  top: 0;
  right: 0; }

.profile-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* --icon-color: #fff; */ }

.profile-menu {
  position: absolute;
  top: 48px;
  right: 8px;
  width: 128px;
  background: #fff;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px; }
  .profile-menu a {
    display: block;
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
    color: #ed454a;
    text-decoration: none;
    font-weight: 700; }
    .profile-menu a:last-child {
      border: none; }

:global(.profile-button svg) {
  stroke: none !important;
  fill: #fff !important; }

/*# sourceMappingURL=src/components/ProfileButton.svelte.map */</style>

<svelte:window on:click={closeMenu} />

<div class="profile" on:click={toggleMenu}>
  <div class="profile-button">
    <Icon name="user" size="small" />
  </div>
  {#if menuVisible}
    <div class="profile-menu" transition:fly={{ y: 16 }}>
      <a href="/#/profil">Min profil</a>
      <a href="#hjelp" on:click|preventDefault={openHelp}>Hjelp</a>
      <a href="/#/utlogging">Logg ut</a>
    </div>
  {/if}
</div>
