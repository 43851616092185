<script>
  import { onMount, onDestroy } from "svelte";
  import { fade } from "svelte/transition";
  import { push } from "svelte-spa-router";
  import Metas from "../Metas.svelte";
  import Badge from "../Badge.svelte";

  export let data = {
    id: 0,
    challenge: null,
    published: "",
    description: "",
    participants: [],
    attachments: []
  };

  $: formattedParticipants = data.participants
    .map(p => p.first_name)
    .reduce((text, name, index, arr) => {
      if (index === arr.length - 1) {
        text += " og ";
      } else if (index <= arr.length - 2) {
        text += ", ";
      }

      return text + name;
    });

  $: formattedTeams = data.participants
    .map(p => "<span class='team'>" + p.team.name + "</span>")
    .filter((name, index, arr) => arr.indexOf(name) === index)
    .reduce((text, name, index, arr) => {
      if (index === arr.length - 1) {
        text += " og ";
      } else if (index <= arr.length - 2) {
        text += ", ";
      }

      return text + name;
    });

  $: formattedAttachments = data.attachments.slice(0, 3);
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.completed-challenge-item {
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid #f0f0f0; }

.icon {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 1/4 - (24px - 24px * 1/4));
  max-width: calc(99.9% * 1/4 - (24px - 24px * 1/4));
  width: calc(99.9% * 1/4 - (24px - 24px * 1/4));
  position: relative; }

.icon:nth-child(1n) {
  margin-right: 24px;
  margin-left: 0; }

.icon:last-child {
  margin-right: 0; }

.icon:nth-child(4n) {
  margin-right: 0;
  margin-left: auto; }

.content {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 3/4 - (24px - 24px * 3/4));
  max-width: calc(99.9% * 3/4 - (24px - 24px * 3/4));
  width: calc(99.9% * 3/4 - (24px - 24px * 3/4)); }

.content:nth-child(1n) {
  margin-right: 24px;
  margin-left: 0; }

.content:last-child {
  margin-right: 0; }

.content:nth-child(4n) {
  margin-right: 0;
  margin-left: auto; }

.text {
  /* width: 90%; */
  margin-bottom: 14px; }

.attachments {
  margin-top: 24px; }

img {
  height: 72px;
  width: 72px;
  position: relative;
  display: inline-block;
  border-radius: 16px;
  border: 2px solid #fff;
  background: #f0f0f0; }
  img + img {
    margin-left: -48px; }
  img:nth-child(1) {
    z-index: 3; }
  img:nth-child(2) {
    z-index: 2; }
  img:nth-child(3) {
    z-index: 1; }
  @media (max-width: 330px) {
    img:nth-child(3) {
      display: none; } }

.read-more {
  display: inline-block;
  margin-left: 16px;
  width: 72px;
  height: 72px;
  border-radius: 16px;
  line-height: 72px;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  background: #f8f8f8;
  color: #ed454a;
  vertical-align: top; }

:global(.completed-challenge-item .text span) {
  font-weight: 700; }

:global(.completed-challenge-item .text .team) {
  color: #ed454a; }

/*# sourceMappingURL=src/components/feed/CompletedChallenge.svelte.map */</style>

<a
  class="feed-item feed-item--std completed-challenge-item"
  in:fade
  href="/#/"
  on:click|preventDefault={() => {
    push('/feed/aktivitet/' + data.id);
  }}>
  <div class="icon">
      <Badge name={data.challenge.icon} />
  </div>
  {#if data.participants.length}
    <div class="content">
      <div class="text">
        {@html formattedParticipants}
        fra
        {@html formattedTeams}
        fullførte {data.challenge.name}
      </div>
      <Metas published={data.published} />
      <div class="attachments">
        {#each formattedAttachments as attachment}
          <img
            src="{attachment.url}?w=144&h=144&fit=crop"
            alt=""
            width="72"
            height="72" />
        {/each}
        <div class="read-more">Les mer &hellip;</div>
      </div>
    </div>
  {/if}
</a>
