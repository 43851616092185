<script>
  import { onMount, onDestroy } from "svelte";
  import { fly } from "svelte/transition";
  import Logo from "../Logo.svelte";
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.top {
  /* min-height: 50vh; */
  background: #ed454a;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0 48px 0; }

.logo {
  width: 50%; }

/*# sourceMappingURL=src/components/feed/FeedTop.svelte.map */</style>

<div class="top" in:fly={{ y: -100 }}>
  <div class="logo">
    <Logo />
  </div>

  <svg width="375" height="68" viewBox="0 0 375 68" class="wave-1">
    <path
      d="M302.283 21.4427C176.282 59.84 71.1523 39.4272 0
      11.619V68H375V0.171021C351.198 6.81757 326.942 13.9377 302.283 21.4427Z"
      fill="white" />
  </svg>
</div>
