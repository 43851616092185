<script>
  import { onMount, onDestroy } from "svelte";
  import { fly, fade } from "svelte/transition";
  import api from "api";
  import { user, goals } from "stores.js";
  import Illustration from "../components/Illustration.svelte";
  import CompletedChallenge from "../components/feed/CompletedChallenge.svelte";

  let elm;
  let height = 0;
  let loaded = false;
  let scrollTop = 0;


  let userData = {
    completed_challenges: []
  };

  let loadedChallenges = 1;

  $: completedChallenges = userData.completed_challenges.slice(0, loadedChallenges);

  $: if(loaded && loadedChallenges <= userData.completed_challenges.length && scrollTop + 100 >= height) {
    loadedChallenges += 1;
  }

  function onResize() {
    height = elm.clientHeight;
  }

  onMount(() => {
    api.get("me").then(res => {
      userData = res.data.user;
      loaded = true;
      onResize();
    });
  });

  onDestroy(() => {});
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.profile > div {
  padding: 64px 0 64px 0;
  position: relative; }
  .profile > div::before {
    content: " ";
    width: 128px;
    height: 1px;
    background: #f0f0f0;
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -64px; }

.top {
  text-align: center; }
  .top h1 {
    color: #ed454a; }

.stats {
  text-align: center;
  position: relative; }
  .stats .container {
    display: flex;
    flex-flow: row wrap; }
    .stats .container > div {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(99.9% * 1/2 - (24px - 24px * 1/2));
      max-width: calc(99.9% * 1/2 - (24px - 24px * 1/2));
      width: calc(99.9% * 1/2 - (24px - 24px * 1/2)); }
    .stats .container > div:nth-child(1n) {
      margin-right: 24px;
      margin-left: 0; }
    .stats .container > div:last-child {
      margin-right: 0; }
    .stats .container > div:nth-child(2n) {
      margin-right: 0;
      margin-left: auto; }
  .stats .h5 {
    color: #ed454a;
    font-weight: 900; }
  .stats .overline {
    margin-top: 8px;
    line-height: 1.5; }

.completed-challenges {
  margin-top: -40px; }

:global(.profile .completed-challenges .feed-item:last-child) {
  border-bottom: none; }

/*# sourceMappingURL=src/routes/Profile.svelte.map */</style>

<svelte:window bind:scrollY={scrollTop} on:resize={onResize} />

<div class="profile" bind:this={elm}>

  {#if loaded}
    <div class="top">
      <div class="container">
        <h1 class="h3" in:fly={{ y: 50 }}>{userData.name}</h1>
      </div>
    </div>

    <div class="stats">
      <div class="container">
        <div in:fly={{ y: 50, delay: 50 }}>
          <div class="circle-wrap">
            <Illustration name="helse" />
          </div>
          <div class="overline">Helse</div>
          <div class="h5">{userData.points.helse} poeng</div>
        </div>
        <div in:fly={{ y: 50, delay: 100 }}>
          <div class="circle-wrap">
            <Illustration name="samfunn" />
          </div>
          <div class="overline">Samfunn</div>
          <div class="h5">{userData.points.samfunn} poeng</div>
        </div>
      </div>
    </div>

    {#if userData.completed_challenges.length}
      <div class="completed-challenges" in:fly={{ y: 50, delay: 150 }}>
        {#each completedChallenges as item}
          <CompletedChallenge data={item} />
        {/each}
      </div>
    {/if}
  {:else}
    <!-- loader -->
  {/if}

</div>
