<script>
  import { fade } from "svelte/transition";
  import { push } from "svelte-spa-router";
  import Metas from "../Metas.svelte";

  export let id = 0;
  export let title = "";
  export let data = {};
  export let type = "standard";
  export let published = "";
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.standard-post {
  padding-bottom: 48px;
  border-left: 4px solid #ed454a;
  padding-left: 20px; }
  .standard-post .h5 {
    color: #ed454a;
    margin-bottom: 24px; }
  .standard-post .text {
    margin-bottom: 14px;
    width: 90%; }

.button {
  display: inline-block;
  margin-top: 16px;
  margin-bottom: 16px; }

img {
  width: 100%;
  margin-bottom: 24px; }

/*# sourceMappingURL=src/components/feed/StandardPost.svelte.map */</style>

<a
  class="feed-item feed-item--std standard-post"
  href="/#/"
  on:click|preventDefault={() => {
    push('/feed/post/' + id);
  }}>
  {#if data.image}
    <img src="{data.image}?w=483&h=300&fit=crop" alt="" />
  {/if}
  <h2 class="h5">{title}</h2>
  <div class="text">
    {#if data.excerpt}
      {@html data.excerpt}
    {/if}

    {#if data.content && data.content.length}
      <div>
        <span class="button button--ghost">Les mer</span>
      </div>
    {/if}
  </div>

  <Metas {published} />
</a>
