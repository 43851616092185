<script>
  import { drawPath } from "transition";
  import { fade } from "svelte/transition";
  import { quadInOut as easing } from "svelte/easing";

  
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.logo {
  fill: none;
  stroke: #fff;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  width: 100%;
  height: auto; }
  .logo .fill {
    fill: #fff;
    stroke: none; }

/*# sourceMappingURL=src/components/Logo.svelte.map */</style>

<svg viewBox="0 0 330 330" class="logo">
  <path
    in:drawPath="{{ duration: 1500, easing: easing }}"
    class="lines"
    d="M195.6,152.8h32.2l14.2-37.6l14.2,37.6l7.9-17.2l9.5,17.2h48.7
    M130.5,152.8H87.9l-15.3-37.7l-15.3,37.7H7
    M111.7,111.6c-6.9,6.9-8.2,14.2-1.4,20.9l27.4,27.4c5.3,5.3,9.9,11.9,22,11.9h16.5c3.5,0,6.5-4.6,6.5-8.1c0-14.9-22.1-11.4-22.1-30
    c0-15.3,3.9-21.6-2-27.4c0,0-12.8,4-18.5-1.8c-2.2-2.2-3.1-5.4-3.4-8.5c0-2.3-1.9-4.1-4.1-4.1c-0.3,0-0.6,0-0.9,0.1L111.7,111.6z
    M150.8,133.8h9.6v-0.2h-9.6V133.8z M150.8,125.8h10v-0.2h-10V125.8z
    M150.8,117.8h10.7v-0.2h-10.7V117.8z M110.3,132.6l27.4,27.4
    c5.3,5.3,9.9,11.9,22,11.9h16.5c3.5,0,6.5-4.6,6.5-8.1c0-0.5,0-1-0.1-1.6h-22.7c-5.1,0-12-9-17.5-15.2s-25.1-28.8-30.6-35.3
    C104.8,118.5,103.5,125.8,110.3,132.6z
    M136.4,95.8c9.6-9.5,25-9.5,34.5,0.1l6.5,6.5l6.5-6.5c9.6-9.5,25-9.5,34.5,0
    c4.6,4.5,7.1,10.5,7.2,17c-0.1,7.1-3,13.9-8.2,18.7c-9.5,9.4-24,23.3-34.2,33.2" />
  <path
    in:drawPath="{{ duration: 700, easing: easing, delay: 700, offset: 1000 }}"
    class="circle"
    d="M322.6,152.3c6.4,87.3-59.1,163.3-146.4,169.7S12.9,263,6.5,175.7S65.6,12.4,152.9,5.9S316.2,65,322.6,152.3z" />
  <path
    in:fade={{ duration: 1000, delay: 1000 }}
    class="fill"
    d="M190.8,52.5c2.4,0,4.1,1.9,4.1,4.4c0,2.5-1.7,4.4-4.1,4.4c-2.4,0-4.1-1.9-4.1-4.4C186.7,54.3,188.4,52.5,190.8,52.5
    M190.8,63.5c3.8,0,6.6-2.8,6.6-6.6c0-3.8-2.8-6.6-6.6-6.6c-3.8,0-6.6,2.8-6.6,6.6C184.1,60.6,187,63.5,190.8,63.5
    M206.9,61.2 l-5.2,0l0-10.7l-2.5,0l0,12.8l7.7,0L206.9,61.2z
    M212.9,61.4c-1.5,0-2.2-0.8-2.7-1.6l-2,1.2c0.5,1,1.8,2.6,4.7,2.6
    c2.6,0,4.3-1.4,4.3-3.6c0-1.7-0.6-2.9-3.5-4.1l-0.9-0.4c-1.2-0.5-1.7-0.9-1.7-1.7c0-0.7,0.7-1.2,1.5-1.2c0.8,0,1.5,0.3,2,1.1
    l1.9-1.2c-0.8-1.4-2.2-2-3.9-2c-2.3,0-4,1.4-4,3.4c0,2.2,1.3,3.1,3.1,4l0.9,0.4c1.2,0.5,2,0.9,2,1.9C214.8,60.8,214,61.4,212.9,61.4
    M227.8,61.2l-6.2,0l0-3.2l4.5,0l0-2.1l-4.5,0l0-3.2l5.9,0l0-2.2l-8.4,0l0,12.8l8.7,0L227.8,61.2z
    M111.8,58.2l5.8,0l0,5.1l2.5,0
    l0-12.8l-2.5,0l0,5.5l-5.8,0l0-5.5l-2.5,0l0,12.8l2.5,0L111.8,58.2z
    M131.6,61.2l-6.2,0l0-3.2l4.5,0l0-2.1l-4.5,0l0-3.2l5.9,0l0-2.2
    l-8.4,0l0,12.8l8.7,0L131.6,61.2z M162.6,50.6l-2.5,0l0,12.8l2.5,0L162.6,50.6z
    M136.1,55.8l7.9,7.5l0.4,0l0-12.8l-2.5,0l0,7.4
    l-7.9-7.4l-0.4,0l0,12.8l2.5,0L136.1,55.8z
    M149.1,55.9l7.9,7.5l0.4,0l0-12.8l-2.5,0l0,7.4l-7.9-7.4l-0.4,0l0,12.8l2.5,0L149.1,55.9
    z
    M171.7,63.6c2.4,0,4.2-0.9,4.9-1.4l0-5.9l-5.4,0l0,2.1l3.1,0l0,2.5l-0.1,0c-0.7,0.4-1.8,0.5-2.6,0.5c-2.6,0-4.4-1.8-4.4-4.4
    c0-2.5,1.6-4.3,4-4.3c1.4,0,2.2,0.5,2.9,1.1l1.7-1.7c-1-1.2-2.5-1.7-4.6-1.7c-1.9,0-3.5,0.7-4.7,1.8c-1.2,1.2-1.8,2.8-1.8,4.7
    C164.8,60.9,167.6,63.6,171.7,63.6 M182.4,56.2h-4v2.1h4V56.2z
    M232.4,55.9l7.9,7.5l0.4,0l0-12.8l-2.5,0l0,7.4l-7.9-7.4l-0.4,0
    l0,12.8l2.5,0L232.4,55.9z
    M94.9,49.1c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C93.1,48.3,93.9,49.1,94.9,49.1
    M94.8,66.8c4.3,0,7.9-2.7,8.4-5.3c0-0.1,0.1-0.5-0.1-0.5c-0.2,0-0.2,0.1-0.4,0.4c-0.8,2-4.1,3.6-7.9,3.6c-3.9,0-7.1-1.7-7.9-3.7
    c-0.1-0.3-0.2-0.5-0.4-0.4c-0.2,0-0.1,0.4-0.1,0.5C86.9,64.1,90.4,66.8,94.8,66.8
    M91.7,53.5c1.9,0,3.3,2,5.4,1.8
    c0.9-0.1,1.6-0.3,2.1-0.8c1.4,0.2,2.3,1.3,2.3,2.6c0,1.4-1.1,2.5-2.7,2c-2.4-0.8-4.7-4-8.2-4c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
    c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2,0-0.3C90.4,53.9,91,53.5,91.7,53.5
    M89.6,62.1c0.8,0,1.3-0.4,1.4-0.6c0,0,0-0.1,0-0.1c0,0,0,0,0,0
    c-0.1-0.1-0.2,0.2-0.9,0.2c-0.7,0-1.4-0.7-1.3-1.3c0.1-0.8,0.8-1.5,1.9-1.3c3,0.4,5.5,3.2,8.1,3.1c2.2-0.1,4-1.8,4.1-4.1
    c0.1-1.9-1.3-3.6-3.2-3.9c0.4-0.5,0.7-1.1,0.7-2c0.1-1.5-1.2-2.8-2.3-2.9c-0.5-0.1-0.8,0-1,0.1c-0.2,0.1-0.1,0.3,0.2,0.3
    c0.8-0.1,1.4,0.4,1.5,1l0,0c0.1,0.8-0.4,1.5-1.2,1.6c-1.6,0.1-2.9-1.8-5.4-2c-1.8-0.1-3.1,1.4-3.1,2.8c0,0.7,0.3,1.4,0.7,1.9
    c0,0,0.1,0.1,0.1,0.1c-2.1,0.3-3.2,1.9-3.2,3.6C86.7,60.2,87.9,62,89.6,62.1
    M85.9,249.4c2.5,0,4.3,0.8,5.6,2.3l5.4,0.1
    c-1.4-5.3-5.1-7.9-11-8c-4,0-7.3,1.4-10,4c-2.7,2.7-4,6-4,10c0,4,1.4,7.4,4,10.1c2.7,2.7,6,4,10,4c4,0,7.5-1.3,10.3-3.8l0-7.9
    l-5.6,0l0,4.8c-1.1,0.8-2.6,1.2-4.7,1.2c-2.5,0-4.6-0.8-6.1-2.3c-1.6-1.5-2.3-3.6-2.3-6.2c0-2.4,0.8-4.4,2.3-6
    C81.4,250.2,83.4,249.4,85.9,249.4
    M122.7,252.4c0-5.2-3.2-7.9-9.6-7.8l-11.9,0l0,5.1l13.2,0c2.2,0.1,3.3,1,3.3,2.8
    c-0.1,2-1.2,3-3.4,3l-13.1,0l0,5.1l10.5,0l6.5,11.3l6.3,0l-6.8-11.9C121,258.9,122.7,256.4,122.7,252.4
    M194,249.6
    c1.7,0,3.2,0.7,4.4,2.2l5.3-0.1c-1.4-2.9-3-4.8-4.8-5.8c-1.9-1-3.8-1.4-5.9-1.4c-2.2,0-4.3,0.7-6.4,2.2c-2.1,1.5-3.1,3.4-3.1,5.8
    c0,2.7,0.9,4.5,2.7,5.6c1.8,1.1,3.8,1.8,6,2c2.4,0.2,3.9,0.9,4.5,1.9c0.6,1,0.6,2.1,0,3.1c-0.6,1-2.1,1.5-4.5,1.5
    c-2.1-0.2-3.8-1.1-5-2.5l-5.3,0c1.3,3.1,3,5.2,5.3,6.2c2.2,1,4.2,1.5,6,1.5c2.7,0,5-0.8,7-2.4c2-1.6,3-3.5,3-5.6
    c0-2.8-0.9-4.8-2.8-6.1c-1.9-1.3-4-2-6.3-2.3c-2.2-0.4-3.6-0.9-4.2-1.6c-0.6-0.7-0.6-1.6,0-2.6C190.4,250.1,191.8,249.6,194,249.6
    M255.4,252.3c0-5.2-3.2-7.9-9.6-7.8l-11.9,0l0,5.1l13.2,0c2.2,0.1,3.3,1,3.3,2.8c-0.1,2-1.2,3-3.4,3l-13.1,0l0,5.1l10.5,0l6.5,11.3
    l6.3,0l-6.8-11.9C253.7,258.8,255.4,256.2,255.4,252.3
    M127.9,266.6l0,5.1l19.9,0l0-5L127.9,266.6z M147.8,244.5l-19.9,0l0,5.1
    l19.9,0L147.8,244.5z M147.8,255.6l-19.9,0l0,5.1l19.9,0L147.8,255.6z
    M208.4,266.6l0,5.1l19.9,0l0-5L208.4,266.6z M228.3,244.5
    l-19.9,0l0,5.1l19.9,0L228.3,244.5z
    M228.3,255.5l-19.9,0l0,5.1l19.9,0L228.3,255.5z
    M172.3,244.5l0,18.1l-13.4-18.1l-5.4,0l0,27.1
    l5.4,0l0-18.1l13.4,18.1l5.4-0.1l0-27.1L172.3,244.5z
    M77.7,229.8c3.8,3.3,8.6,5,14.4,4.9c5.8,0,10.6-1.7,14.4-5
    c3.8-3.3,5.7-7.3,5.7-12.1l0-29.5l-9.3,0l0,27c0,3.5-1,6.1-3.1,7.8c-2.1,1.7-4.7,2.5-7.8,2.5c-3.1,0-5.7-0.8-7.8-2.5
    c-2.1-1.7-3.1-4.2-3.1-7.8l0-27l-9.3,0l0,29.5C71.9,222.5,73.8,226.5,77.7,229.8
    M135.4,196.9l0,37.8l9.3,0l0-37.8l14.5-0.1l0-8.7 l-38.3,0l0,8.7L135.4,196.9z
    M169.3,234.8l34.2,0l0-8.7l-34.2,0L169.3,234.8z
    M203.5,196.9l0-8.7l-34.2,0.1l0,8.7L203.5,196.9z
    M169.3,215.8l34.2,0l0-8.7l-34.2,0L169.3,215.8z
    M222.9,234.7l0-31l23.1,31.1l9.2-0.1l0-46.5l-9.2,0l0,31.2l-23.1-31.1l-9.3,0
    l0,46.5L222.9,234.7z" />
</svg>
