<script>
  import { onMount, onDestroy } from "svelte";
  import Metas from "../components/Metas.svelte";
  import api from "api";
  import { nl2p } from "helpers.js";

  export let id = -1;

  let post = null;
  let slider;
  let sliderElm;

  onMount(() => {
    api
      .get(`post/${id}`)
      .then(res => {
        post = res.data;
      })
      .catch(error => {});
  });
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
section {
  width: 100vw;
  min-height: 100vh;
  background: #fff; }

.top {
  background: #ed454a;
  color: #fff;
  --illu-fill: color(red);
  --illu-stroke: #fff;
  --illu-stroke-alt: #fff;
  padding: 80px 0 104px 0;
  position: relative; }
  .top h1 {
    margin-top: 16px; }

.content {
  padding: 64px 0; }

:global(.post .top .metas svg) {
  stroke: #fff !important; }

:global(.post p) {
  margin-bottom: 32px; }

:global(.post img) {
  width: 100%;
  height: auto;
  margin-bottom: 32px; }

/*# sourceMappingURL=src/routes/Post.svelte.map */</style>

<section class="post">
  {#if post}
    <div class="top">
      <div class="container">
        <Metas published={post.published} />
        <h1 class="h4">{post.title}</h1>
      </div>

      <svg width="375" height="68" viewBox="0 0 375 68" class="wave-1">
        <path
          d="M302.283 21.4427C176.282 59.84 71.1523 39.4272 0
          11.619V68H375V0.171021C351.198 6.81757 326.942 13.9377 302.283
          21.4427Z"
          fill="white" />
      </svg>
    </div>

    {#if post.data.content}
      <div class="content">
        {#each post.data.content as row}
          <div class="row">
            {#if row.layout === 'text'}
              <div class="container">
                {@html nl2p(row.attributes.content)}
              </div>
            {:else if row.layout === 'image'}
              <img src="{row.attributes.image}" alt="" />
            {/if}
          </div>
        {/each}
      </div>
    {/if}
  {:else}
    <!-- loader -->
  {/if}

</section>
