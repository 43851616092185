<script>
  import api from "api";
  import { fly, fade, slide } from "svelte/transition";
  import { onMount, onDestroy } from "svelte";
  import Icon from "../components/Icon.svelte";
  import { nl2p } from "helpers.js";

  let plannedActivities;
  let openedEntry = -1;
  let leaving = -1;
  let joining = -1;

  function joinActivity(activity) {
    if (joining > -1) return;
    joining = activity.id;

    api.post("calendar/" + activity.id + "/join").then(res => {
      joining = -1;
      plannedActivities = res.data;
    });
  }

  function leaveActivity(activity) {
    if (leaving > -1) return;
    leaving = activity.id;

    api.post("calendar/" + activity.id + "/leave").then(res => {
      leaving = -1;
      plannedActivities = res.data;
    });
  }

  onMount(() => {
    api.get("calendar").then(res => {
      plannedActivities = res.data;
    });
  });

  onDestroy(() => {});
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.top {
  background: #ed454a;
  /* color: color(red); */
  padding: 64px 0 96px 0;
  position: relative;
  color: #fff;
  margin-bottom: 10vw; }

.entry {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #f0f0f0;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 40px;
  padding-bottom: 40px; }
  .entry:last-child {
    border-bottom: none; }
  .entry .row {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 16px;
    align-items: center; }
    .entry .row .icon {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(99.9% * 1/4 - (24px - 24px * 1/4));
      max-width: calc(99.9% * 1/4 - (24px - 24px * 1/4));
      width: calc(99.9% * 1/4 - (24px - 24px * 1/4));
      display: flex;
      justify-content: flex-end;
      padding-top: 0.25rem; }
    .entry .row .icon:nth-child(1n) {
      margin-right: 24px;
      margin-left: 0; }
    .entry .row .icon:last-child {
      margin-right: 0; }
    .entry .row .icon:nth-child(4n) {
      margin-right: 0;
      margin-left: auto; }
    .entry .row .content {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(99.9% * 3/4 - (24px - 24px * 3/4));
      max-width: calc(99.9% * 3/4 - (24px - 24px * 3/4));
      width: calc(99.9% * 3/4 - (24px - 24px * 3/4)); }
    .entry .row .content:nth-child(1n) {
      margin-right: 24px;
      margin-left: 0; }
    .entry .row .content:last-child {
      margin-right: 0; }
    .entry .row .content:nth-child(4n) {
      margin-right: 0;
      margin-left: auto; }
  .entry .h6 {
    font-weight: 700;
    color: #ed454a; }
  .entry .read-more {
    border-bottom: 2px solid #ed454a; }
  .entry .button {
    display: inline-block;
    margin-top: 8px; }

:global(.calendar p) {
  margin-bottom: 16px; }

:global(.calendar p:last-child) {
  margin-bottom: 0px; }

/*# sourceMappingURL=src/routes/Calendar.svelte.map */</style>

{#if plannedActivities}
  <div class="calendar">
    <div class="top" in:fly={{ y: -100 }}>
      <div class="container">
        <div class="title">
          <div class="h5">Planlagte</div>
          <div class="h4">Fellesutfordringer</div>
        </div>
      </div>
      <svg width="375" height="68" viewBox="0 0 375 68" class="wave-1">
        <path
          d="M302.283 21.4427C176.282 59.84 71.1523 39.4272 0
          11.619V68H375V0.171021C351.198 6.81757 326.942 13.9377 302.283
          21.4427Z"
          fill="#ffffff" />
      </svg>
    </div>

    <div class="entries" in:fly={{ y: 100 }}>
      {#each plannedActivities as activity, i}
        <div
          class="entry"
          on:click={event => {
            if (!event.target.classList.contains('button')) {
              openedEntry = i;
            }
          }}>
          <div class="row">
            <div class="icon" />
            <div class="content">
              <div class="h6">{activity.name}</div>
            </div>
          </div>
          <div class="row">
            <div class="icon">
              <Icon name="clock" size="medium" />
            </div>
            <div class="content">
              <div class="time ">
                <span>{activity.date} kl. {activity.time}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="icon">
              <Icon name="location" size="medium" />
            </div>
            <div class="content">
              <div class="place">
                <span>{activity.location}</span>
              </div>
            </div>
          </div>
          {#if activity.sign_up_text}
            <div class="row">
              <div class="icon" />
              <div class="content">
                <p>{activity.sign_up_text}</p>
              </div>
            </div>
          {/if}
          {#if activity.description && openedEntry === i}
            <div class="row">
              <div class="icon" />
              <div class="content">
                <div class="description">
                  {@html nl2p(activity.description)}
                </div>
              </div>
            </div>
          {:else if activity.description}
            <div class="row">
              <div class="icon" />
              <div class="content">
                <span class="read-more">Les mer</span>
              </div>
            </div>
          {/if}
          {#if !activity.sign_up_text}
            <div class="row">
              <div class="icon" />
              <div class="content">
                {#if activity.signed_up}
                  <span
                    class="button button--ghost"
                    on:click={() => {
                      leaveActivity(activity);
                    }}>
                    {#if leaving === activity.id}
                      Melder av...
                    {:else}Meld deg av{/if}
                  </span>
                {:else}
                  <span
                    class="button button--red"
                    on:click={() => {
                      joinActivity(activity);
                    }}>
                    {#if joining === activity.id}
                      Melder på...
                    {:else}Meld deg på{/if}
                  </span>
                {/if}
              </div>
            </div>
          {/if}
        </div>
      {/each}
    </div>
  </div>
{:else}
  <!-- loader -->
{/if}
