<script>
  import { onMount, tick } from "svelte";

  // props
  export let items;
  export let itemHeight = undefined;
  export let paused = false;

  // read-only, but visible to consumers via bind:start
  export let start = 0;
  export let end = 0;

  // local state
  let viewport;
  let height_map = [];
  let rows;
  let contents;
  let viewport_height = 0;
  let visible;
  let mounted;
  let scrollTop;
  let frozen = false;
  let fixed = false;

  let top = 0;
  let prevTop = 0;
  let bottom = 0;
  let average_height;

  $: visible = items.slice(start, end).map((data, i) => {
    return { index: i + start, data };
  });

  // whenever `items` changes, invalidate the current heightmap
  $: if (mounted) refresh(items, viewport_height, itemHeight);

  async function refresh(items, viewport_height, itemHeight) {
    if (frozen) return;
    await tick(); // wait until the DOM is up to date

    let content_height = top - scrollTop;
    let i = start;

    while (content_height < viewport_height && i < items.length) {
      let row = rows[i - start];

      if (!row) {
        end = i + 1;
        await tick(); // render the newly visible row
        row = rows[i - start];
      }

      const row_height = (height_map[i] = itemHeight || row.offsetHeight);
      content_height += row_height;
      i += 1;
    }

    end = i;

    const remaining = items.length - end;
    average_height = (top + content_height) / end;

    bottom = remaining * average_height;
    height_map.length = items.length;
  }

  async function handle_scroll() {
    if (frozen) return;
    const old_start = start;

    for (let v = 0; v < rows.length; v += 1) {
      height_map[start + v] = itemHeight || rows[v].offsetHeight;
    }

    let i = 0;
    let y = 0;

    let maybePrevScrollTop = scrollTop;

    if (prevTop) {
      maybePrevScrollTop = prevTop;
      prevTop = 0;
    }

    while (i < items.length) {
      const row_height = height_map[i] || average_height;
      if (y + row_height > maybePrevScrollTop) {
        start = i;
        top = y;

        break;
      }

      y += row_height;
      i += 1;
    }

    while (i < items.length) {
      y += height_map[i] || average_height;
      i += 1;

      if (y > scrollTop + viewport_height) break;
    }

    end = i;

    const remaining = items.length - end;
    average_height = y / end;

    while (i < items.length) height_map[i++] = average_height;
    bottom = remaining * average_height;
  }

  // trigger initial refresh
  onMount(() => {
    rows = contents.getElementsByTagName("svelte-virtual-list-row");
    mounted = true;
  });

  $: if(paused) {
    freeze();
  } else {
    unfreeze();
  }

  function freeze() {
    prevTop = window.pageYOffset || document.documentElement.scrollTop;
    contents.style.top = "-" + prevTop + "px";
    fixed = true;
    frozen = true;
  };

  function unfreeze() {
    if (!frozen) return;

    fixed = false;
    contents.style.top = "0px";

    setTimeout(() => {
      window.scrollTo(0, prevTop);

      setTimeout(() => {
        frozen = false;
        handle_scroll();
      }, 100);
    }, 0);
  };
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
svelte-virtual-list-viewport {
  position: relative;
  -webkit-overflow-scrolling: touch;
  display: block; }
  svelte-virtual-list-viewport.fixed {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw; }

svelte-virtual-list-contents,
svelte-virtual-list-row {
  display: block; }

svelte-virtual-list-contents.absoluted {
  position: absolute; }

svelte-virtual-list-row {
  overflow: hidden; }

/*# sourceMappingURL=src/components/VirtualList.svelte.map */</style>

<svelte:window
  bind:innerHeight={viewport_height}
  on:scroll={handle_scroll}
  bind:scrollY={scrollTop} />

<svelte-virtual-list-viewport bind:this={viewport} class:fixed>
  <svelte-virtual-list-contents
    bind:this={contents}
    style="padding-top: {top}px; padding-bottom: {bottom}px;"
    class:absoluted={fixed}>
    {#each visible as row (row.index)}
      <svelte-virtual-list-row>
        <slot item={row.data}>Missing template</slot>
      </svelte-virtual-list-row>
    {/each}
  </svelte-virtual-list-contents>
</svelte-virtual-list-viewport>
