<script>
  import { onMount, onDestroy } from "svelte";
  import Chartist from "chartist";

  export let percent = 0;

  let chart;
  let chartElm;

  onMount(() => {
    chart = new Chartist.Pie(
      chartElm,
      {
        series: [
          {
            value: 100 - percent,
            name: "rest",
            className: "rest"
          },
          {
            value: percent,
            // name: "percent",
            className: "percent"
          }
        ]
      },
      {
        donut: true,
        total: 100,
        showLabel: false,
        donutWidth: 0,
        width: 0,
      }
    );
  });

  onDestroy(() => {
    chart.detach();
  });
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.percent-circle {
  position: relative;
  width: 100%; }

.height {
  padding-bottom: 100%;
  position: relative; }

:global(.percent-circle .chart) {
  position: absolute;
  width: 100%;
  height: 100%; }

:global(.percent-circle svg) {
  transform: scale(-1, 1); }

:global(.percent-circle .percent path) {
  stroke: #ed454a;
  stroke-width: 8px !important;
  stroke-linejoin: round;
  stroke-linecap: round; }

:global(.percent-circle .rest path) {
  stroke: #f0f0f0;
  stroke-width: 1px !important;
  z-index: 1; }

.icon {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

/*# sourceMappingURL=src/components/PercentCircle.svelte.map */</style>

<div class="percent-circle">
  <div class="height">
    <div class="chart" bind:this={chartElm} />

    <div class="icon">
      <slot />
    </div>

  </div>
</div>
