<script>
  import api from "api";
  import { onMount, onDestroy } from "svelte";
  import { fly, fade } from "svelte/transition";
  import Badge from "../components/Badge.svelte";
  import Icon from "../components/Icon.svelte";
  import Illustration from "../components/Illustration.svelte";
  import SubmitChallenge from "../components/SubmitChallenge.svelte";
  import { nl2p } from "helpers.js";

  let loaded = false;
  let weeklyChallenges = [];
  let filteredWeeklyChallenges = [];
  let selectedCategory = "all";
  let weekNum = "";
  let selectedWeeklyChallenge;
  let entriesContainer;
  let challengesWrap;
  let modal;
  let showModal;
  let showForm;
  let windowHeight;
  let scrollY = 0;

  $: if (selectedCategory === "all") {
    filteredWeeklyChallenges = weeklyChallenges;
  } else {
    filteredWeeklyChallenges = weeklyChallenges.filter(
      item => item.challenge.category === selectedCategory
    );
  }

  function showChallenge(index) {
    const target = entriesContainer.getElementsByClassName("entry")[index];

    modal.style = "";
    modal.style = `
      top: ${target.getBoundingClientRect().top}px;
      left: ${target.getBoundingClientRect().left}px;
      width: ${target.offsetWidth}px;
      height: ${target.offsetHeight}px;
    `;

    scrollY = window.pageYOffset || document.documentElement.scrollTop;
    document.querySelector("body").classList.add("disable-scroll");
    challengesWrap.style.position = `absolute`;
    challengesWrap.style.top = `-${scrollY}px`;

    setTimeout(() => {
      showModal = true;
      // use js instead of css for height to get correct height on iOS
      // (when navbar etc is visible)
      modal.style.height = window.innerHeight - 48 + "px";
    }, 100);
  }

  function hideChallenge() {
    showModal = false;
    showForm = false;

    document.querySelector("body").classList.remove("disable-scroll");
    challengesWrap.style.position = `relative`;
    challengesWrap.style.top = `auto`;
    window.scrollTo(0, scrollY);
  }

  function filter(category) {
    let res = weeklyChallenges;

    if (category !== "all") {
      res.filter(item => item.category === category);
    }

    filteredWeeklyChallenges = res;
  }

  onMount(() => {
    api
      .get("weekly-challenge")
      .then(res => {
        weeklyChallenges = res.data;

        if (weeklyChallenges.length) {
          weekNum = weeklyChallenges[0].week;
        }

        loaded = true;
      })
      .catch(error => {
        // probably not authenticated, let api.js handle login
      });
  });

  onDestroy(() => {
    hideChallenge();
    // window.scrollTo(0,0);
  });
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.challenges {
  background: #fff;
  padding-bottom: 64px;
  width: 100vw; }

.top {
  background: #ed454a;
  /* color: color(red); */
  padding: 40px 0 112px 0;
  position: relative;
  color: #fff;
  margin-bottom: 10vw; }
  .top .filters {
    width: 100%;
    margin-top: 24px; }
    .top .filters > span {
      border-bottom: 2px solid rgba(255, 255, 255, 0);
      transition: all 0.3s;
      padding-bottom: 2px;
      margin-right: 8px; }
      .top .filters > span.selected {
        border-color: white; }

.entries {
  display: flex;
  flex-flow: row wrap;
  margin-top: -104px;
  position: relative; }

.entry {
  width: 100%;
  background: #fff;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 1/2 - (24px - 24px * 1/2));
  max-width: calc(99.9% * 1/2 - (24px - 24px * 1/2));
  width: calc(99.9% * 1/2 - (24px - 24px * 1/2));
  margin-bottom: 24px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  position: relative;
  text-align: center; }

.entry:nth-child(1n) {
  margin-right: 24px;
  margin-left: 0; }

.entry:last-child {
  margin-right: 0; }

.entry:nth-child(2n) {
  margin-right: 0;
  margin-left: auto; }
  .entry .text {
    font-weight: 700;
    line-height: 1;
    padding: 0 32px 64px 32px; }
  .entry .icon {
    padding: 32px;
    display: flex;
    justify-content: center; }
  .entry .category {
    position: absolute;
    height: 48px;
    width: 100%;
    bottom: 0;
    border-top: 1px solid #f0f0f0;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center; }
    .entry .category > div {
      padding: 0 8px; }
    .entry .category span {
      font-size: 0.5rem;
      line-height: 5rem;
      text-transform: uppercase; }

.entry-single {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 2/2 - (24px - 24px * 2/2));
  max-width: calc(99.9% * 2/2 - (24px - 24px * 2/2));
  width: calc(99.9% * 2/2 - (24px - 24px * 2/2)); }

.entry-single:nth-child(1n) {
  margin-right: 24px;
  margin-left: 0; }

.entry-single:last-child {
  margin-right: 0; }

.entry-single:nth-child(2n) {
  margin-right: 0;
  margin-left: auto; }
  .entry-single .desc {
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px; }
    .entry-single .desc .h5 {
      margin-bottom: 16px; }
  .entry-single .icon {
    padding-bottom: 8px; }

.title .h4 {
  font-weight: 700; }

.modal {
  will-change: width, height, top, left;
  position: fixed;
  transition: all 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 16px;
  opacity: 0;
  visibility: hidden; }
  .modal.show {
    visibility: visible;
    width: calc(100vw - 48px) !important;
    /* height: calc(100vh - 48px) !important; */
    top: 24px !important;
    left: 24px !important;
    z-index: 20;
    opacity: 1; }
  .modal.expand {
    width: 100vw !important;
    height: 100vh !important;
    top: 0 !important;
    left: 0 !important;
    border-radius: 0; }

.submit-challenge {
  width: 100%;
  position: fixed;
  overflow: auto;
  height: 100vh;
  z-index: 100;
  top: 0;
  left: 0;
  -webkit-overflow-scrolling: touch; }

.challenge-description {
  padding: 32px;
  width: 100%;
  height: 100%;
  position: relative; }
  .challenge-description .content {
    width: 100%;
    height: calc(100% - 104px);
    overflow: auto;
    display: flex; }
    .challenge-description .content > div {
      margin: auto;
      padding: 32px 0; }
  .challenge-description .title {
    text-align: center;
    margin-bottom: 32px; }
  .challenge-description .icon {
    margin: auto;
    width: 104px;
    height: 104px;
    margin-bottom: 32px; }
  .challenge-description .description {
    text-align: center; }
  .challenge-description .action {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56px;
    border-top: 1px solid #f0f0f0;
    text-align: center; }
    .challenge-description .action .button {
      display: inline-block;
      padding-top: 0;
      padding-bottom: 0;
      height: 56px;
      line-height: 56px;
      transform: translateY(-50%); }
    .challenge-description .action .button--red {
      animation: redPulse 2s infinite; }
    .challenge-description .action .button--ghost {
      background: #fff; }
  .challenge-description .meta {
    height: 32px;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    font-size: 0.75rem;
    line-height: 1;
    /* color: #999; */
    align-items: center;
    margin-bottom: 0px;
    text-transform: uppercase; }
    .challenge-description .meta > div {
      flex: 1 1;
      /* padding: 0 32px; */ }
    .challenge-description .meta div:last-child {
      text-align: right;
      flex: 0 1; }

:global(.challenge-description p) {
  margin-bottom: 32px; }

.close-button {
  width: 64px;
  height: 64px;
  position: fixed;
  bottom: 48px;
  right: 8px;
  border-radius: 50%;
  z-index: 101;
  background: #ed454a;
  transform: rotate(45deg); }
  .close-button::before {
    content: " ";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.075);
    position: absolute;
    transition: opacity 0.4s;
    transition-delay: 0.4s; }

:global(.close-button svg) {
  stroke: #fff !important; }

/*# sourceMappingURL=src/routes/Challenges.svelte.map */</style>

<svelte:window bind:innerHeight={windowHeight} />

<div class="challenges" bind:this={challengesWrap}>
  {#if weeklyChallenges}
    <div class="top" in:fly={{ y: -100 }}>
      <div class="container">
        <div class="title">
          <div class="h5">Ukas</div>
          <div class="h4">Utfordringer</div>
        </div>
        <div class="filters">
          <span
            class="filter"
            class:selected={selectedCategory === 'all'}
            on:click={() => {
              selectedCategory = 'all';
            }}>
            Alle
          </span>
          <span
            class="filter"
            class:selected={selectedCategory === 'helse'}
            on:click={() => {
              selectedCategory = 'helse';
            }}>
            Helse
          </span>
          <span
            class="filter"
            class:selected={selectedCategory === 'samfunn'}
            on:click={() => {
              selectedCategory = 'samfunn';
            }}>
            Samfunn
          </span>
        </div>
      </div>
      <svg width="375" height="68" viewBox="0 0 375 68" class="wave-1">
        <path
          d="M302.283 21.4427C176.282 59.84 71.1523 39.4272 0
          11.619V68H375V0.171021C351.198 6.81757 326.942 13.9377 302.283
          21.4427Z"
          fill="#ffffff" />
      </svg>
    </div>

    <div
      class="entries container"
      bind:this={entriesContainer}
      in:fly={{ y: 100 }}>
      {#if weeklyChallenges.length}
        {#each filteredWeeklyChallenges as w, i}
          <div
            class="entry"
            on:click={() => {
              selectedWeeklyChallenge = w;
              showChallenge(i);
            }}>
            <div class="icon">
              <Badge name={w.challenge.icon} />
            </div>
            <div class="text">
              <div>{w.challenge.name}</div>
            </div>
            {#if w.completed}
              <div class="category completed">
                <Illustration name="check" size="24" />
                <div>
                  <span class="">Fullført</span>
                </div>
              </div>
            {:else}
              <div class="category">
                <Illustration name={w.challenge.category} size="24" />
                <div>
                  <span class="">{w.challenge.category}</span>
                </div>
              </div>
            {/if}
          </div>
        {/each}
      {:else if loaded}
        <div class="entry entry-single">
          <div class="icon">
            <Illustration name="uten-grenser-icon" />
          </div>
          <div class="desc">
            <p class="h5">Takk for innsatsen!</p>

            <p>
              Uten Grenser er over for denne gang. Tusen takk for innsatsen alle sammen! 
            </p>
          </div>
        </div>
      {/if}

    </div>
  {:else}
    <!-- loader -->
  {/if}
</div>

<div
  class="modal"
  bind:this={modal}
  class:show={showModal}
  class:expand={showForm}>
  {#if selectedWeeklyChallenge && !showForm}
    <div class="challenge-description">
      <div class="meta">
        <div>{selectedWeeklyChallenge.challenge.category}</div>
        <div>{selectedWeeklyChallenge.challenge.points}&nbsp;poeng</div>
      </div>
      <div class="content">
        <div>
          <div class="title">
            <div class="icon">
              <Badge name={selectedWeeklyChallenge.challenge.icon} />
            </div>
            <div class="h4">{selectedWeeklyChallenge.challenge.name}</div>
          </div>
          {#if selectedWeeklyChallenge.challenge.description}
            <div class="description">
              {@html nl2p(selectedWeeklyChallenge.challenge.description)}
            </div>
          {/if}
        </div>
      </div>
      <div class="action">
        {#if !selectedWeeklyChallenge.completed}
          <span
            class="button button--red"
            on:click={() => {
              showForm = true;
            }}>
            Fullfør
          </span>
        {:else}
          <span class="button button--ghost">Fullført</span>
        {/if}
      </div>
    </div>
  {/if}
</div>

{#if showForm}
  <div class="submit-challenge" in:fade>
    <SubmitChallenge challenge={selectedWeeklyChallenge.challenge} />
  </div>
{/if}

{#if showModal}
  <div
    on:click={hideChallenge}
    class="close-button"
    in:fly={{ x: 100, delay: 400 }}>
    <Icon name="plus" size="large" />
  </div>
{/if}
