<script>
  export let name = "";
  export let size = "large";

  const sizes = {
    large: 64,
    medium: 32,
    small: 16
  };

  $: selectedSize = sizes[size];
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.icon {
  fill: none;
  stroke: var(--icon-color, #000);
  stroke-miterlimit: 10;
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-linecap: round; }
  .icon.small {
    stroke-width: 4px; }

/*# sourceMappingURL=src/components/Icon.svelte.map */</style>

<svg class="icon {size}" width={selectedSize} height={selectedSize}>
  <use xlink:href="#{name}" />
</svg>
