<script>
  import Illustration from "./Illustration.svelte";
  export let name = "";
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.badge {
  width: 100%;
  position: relative;
  max-width: 120px; }

.height {
  padding-bottom: 100%;
  position: relative; }

.icon {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ed454a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px; }

:global(.badge .icon svg) {
  --illu-stroke-alt: #fff;
  --illu-stroke: #fff;
  --illu-fill: #fff;
  max-width: 64px;
  height: auto; }

/*# sourceMappingURL=src/components/Badge.svelte.map */</style>

<div class="badge">
  <div class="height">
    <div class="icon">
      <Illustration {name} />
    </div>
  </div>
</div>
