<script>
  export let name = "";
  export let size = 64;
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.illu {
  fill: none;
  stroke-miterlimit: 10;
  stroke-width: var(--illu-stroke-width, 2px);
  stroke-linejoin: round;
  stroke-linecap: round; }

/*# sourceMappingURL=src/components/Illustration.svelte.map */</style>

<svg class="illu" width="{size}" height="{size}">
  <use xlink:href="#{name}" />
</svg>
