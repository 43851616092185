<script>
  import Icon from "./Icon.svelte";

  export let published = "";
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.metas {
  font-size: 0.5rem;
  font-weight: 700;
  display: flex;
  line-height: 1; }

.meta {
  position: relative;
  opacity: 0.5;
  padding-left: 24px;
  margin-right: 8px; }
  .meta:last-child {
    margin-right: 0; }

:global(.metas .meta .icon) {
  position: absolute;
  left: 0;
  top: -4px; }

/*# sourceMappingURL=src/components/Metas.svelte.map */</style>

<div class="metas">
  <div class="meta">
    <Icon name="clock" size="small" />
    <span>{published}</span>
  </div>
  <!-- <div class="meta">
          <Icon name="chat-message" size="small" />
          <span />
        </div> -->
</div>
