<script>
  import { link, location } from "svelte-spa-router";
  import Illustration from "./Illustration.svelte";
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
nav {
  background: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.1); }
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    nav {
      padding-bottom: calc(env(safe-area-inset-bottom) - 8px); } }

a {
  display: block;
  flex: 1 1;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  color: #343032;
  font-size: 0.5rem;
  line-height: 1;
  position: relative;
  padding: 8px 0; }
  a:not(.active) {
    opacity: 0.5;
    --illu-stroke: #000;
    --illu-stroke-alt: #000;
    --illu-fill: #000; }

span {
  margin-top: 1px;
  display: block; }

/*# sourceMappingURL=src/components/Nav.svelte.map */</style>

<nav>
  <a href="/" use:link class:active={$location === '/'}>
    <Illustration name="users" size="32" />
    <span>Dashboard</span>
  </a>
  <a href="/feed" use:link class:active={$location === '/feed'}>
    <Illustration name="megaphone" size="32" />
    <span>Feed</span>
  </a>
  <a href="/utfordringer" use:link class:active={$location === '/utfordringer'}>
    <Illustration name="uten-grenser-icon" size="32" />
    <span>Utfordringer</span>
  </a>
  <a href="/kalender" use:link class:active={$location === '/kalender'}>
    <Illustration name="calendar" size="32" />
    <span>Kalender</span>
  </a>
</nav>
