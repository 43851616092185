<script>
  import { fade } from "svelte/transition";
  import Illustration from "../Illustration.svelte";

  export let data;

  $: userName = data.user.first_name + " " + data.user.last_name;
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.planned-activity-item {
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid #f0f0f0; }

.icon {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 1/4 - (24px - 24px * 1/4));
  max-width: calc(99.9% * 1/4 - (24px - 24px * 1/4));
  width: calc(99.9% * 1/4 - (24px - 24px * 1/4));
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start; }

.icon:nth-child(1n) {
  margin-right: 24px;
  margin-left: 0; }

.icon:last-child {
  margin-right: 0; }

.icon:nth-child(4n) {
  margin-right: 0;
  margin-left: auto; }

:global(.planned-activity-item .icon svg) {
  width: 100%;
  height: auto;
  max-width: 64px;
  padding-left: 8px;
  padding-right: 8px; }

.content {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 3/4 - (24px - 24px * 3/4));
  max-width: calc(99.9% * 3/4 - (24px - 24px * 3/4));
  width: calc(99.9% * 3/4 - (24px - 24px * 3/4)); }

.content:nth-child(1n) {
  margin-right: 24px;
  margin-left: 0; }

.content:last-child {
  margin-right: 0; }

.content:nth-child(4n) {
  margin-right: 0;
  margin-left: auto; }

/*# sourceMappingURL=src/components/feed/PlannedActivityUser.svelte.map */</style>

<div class="planned-activity-item feed-item" in:fade>
  <div class="icon">
    <Illustration name="calendar" size="64" />
  </div>
  <div class="content">
    <strong>{userName}</strong>
    har meldt seg på
    <strong>{data.planned_activity.name}</strong>
  </div>
</div>
