<script>
  import api from "api";
  import { onMount, onDestroy } from "svelte";
  import { fly } from "svelte/transition";
  import { location, pop } from "svelte-spa-router";
  import VirtualList from "../components/VirtualList.svelte";
  import CompletedChallengeRoute from "./CompletedChallenge.svelte";
  import PostRoute from "./Post.svelte";
  import Icon from "../components/Icon.svelte";
  import CompletedChallenge from "../components/feed/CompletedChallenge.svelte";
  import FeedTop from "../components/feed/FeedTop.svelte";
  import Post from "../components/feed/Post.svelte";
  import PlannedActivityUser from "../components/feed/PlannedActivityUser.svelte";

  export let params = { type: null, id: null };

  /**
   * Feed.
   */
  let items = [{ type: "FeedTop", data: {} }];
  let newItems = [];
  let end = 0;
  let start = 0;
  let fetching = false;
  let polling = false;
  let nextUrl = "feed-item";
  let prevFetchTime = false;

  const feedTypes = {
    "App\\CompletedChallenge": CompletedChallenge,
    "App\\Post": Post,
    "App\\PlannedActivityUser": PlannedActivityUser,
    FeedTop: FeedTop
  };

  function fetchItems() {
    if (fetching || !nextUrl) return;
    fetching = true;

    api.get(nextUrl).then(res => {
      items = [...items, ...res.data.data];
      prevFetchTime = res.data.meta.now;

      fetching = false;

      if (res.data.links.next) {
        nextUrl = res.data.links.next.substr("https://uten-grenser.nws.cloud/api/".length);
      } else {
        nextUrl = false;
      }
    });
  }

  // fetch more items when virtual scroll is close to bottom
  $: if (end >= items.length - 8) {
    fetchItems();
  }

  function pollItems() {
    if (polling || items.length < 2 || !prevFetchTime) return;
    polling = true;

    api.get("feed-item?after=" + prevFetchTime).then(res => {
      prevFetchTime = res.data.meta.now;
      newItems = [...newItems, ...res.data.data];
      polling = false;
    });
  }

  // insert newly posted items when virtual scroll is close to top and there are new items
  $: if (start < 3 && newItems.length) {
    items = [items[0], ...newItems, ...items.slice(1)];
    newItems = [];
  }

  /**
   * Single post.
   */
  let windowWidth = 0;

  const postTypes = {
    aktivitet: CompletedChallengeRoute,
    post: PostRoute
  };

  $: showPost = params.type && params.id;

  $: if (!showPost) {
    // check for new items whenever returning to the feed
    pollItems();
  }

  /**
   * Let transition finnish before removing position fixed from feed.
   * Using fixed modal etc. didn't work on iOS without freezing feed and/or
   * modal content.
   */
  let fixedFeedTimeout = null;
  let pauseFeed = false;
  let mounted = false;

  $: if (mounted && showPost) {
    clearTimeout(fixedFeedTimeout);
    pauseFeed = true;
  } else {
    fixedFeedTimeout = setTimeout(() => {
      pauseFeed = false;
    }, 300);
  }

  let pollInterval = null;

  /**
   * Mount.
   */
  onMount(() => {
    fetchItems();

    pollInterval = setInterval(pollItems, 20000);
    mounted = true;
  });

  onDestroy(() => {
    clearInterval(pollInterval);
    mounted = false;
  });
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.feed-wrap {
  width: 100vw;
  overflow-x: hidden; }

:global(.feed-wrap > svelte-virtual-list-viewport) {
  transition: all 0.4s;
  padding-bottom: 48px; }

:global(.feed-wrap.hidden > svelte-virtual-list-viewport) {
  transform: translateX(-100px);
  transition-duration: 0.7s;
  opacity: 0; }

.post-wrap {
  min-height: 100vh;
  background: #fff; }

.post-nav {
  position: fixed;
  bottom: 0px;
  right: 0;
  width: 136px;
  z-index: 103; }

.post-nav-bg {
  padding-bottom: 90%;
  position: relative; }
  .post-nav-bg svg {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    position: absolute; }

.post-nav-bg-1 {
  fill: #fcf8ea; }

.post-nav-bg-2 {
  fill: #ed454a; }

.post-nav-button {
  background: #fff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  width: 56px;
  height: 56px;
  position: absolute;
  border-radius: 50%;
  z-index: 10;
  bottom: 32px;
  right: 16px; }

:global(.post-nav-button svg) {
  stroke: #ed454a !important;
  stroke-width: 3px !important;
  padding: 8px;
  width: 56px;
  height: 56px; }

.modal {
  position: relative; }

/*# sourceMappingURL=src/routes/Feed.svelte.map */</style>

<svelte:window bind:innerWidth={windowWidth} />

<div class="feed-wrap" class:hidden={showPost}>
  <VirtualList {items} bind:end bind:start let:item paused={pauseFeed}>
    <svelte:component this={feedTypes[item.type]} data={item.data} />
  </VirtualList>
</div>

{#if showPost}
  <div class="modal" style="z-index: 102;">
    <div
      class="post-wrap"
      in:fly={{ x: windowWidth, opacity: 1 }}
      out:fly={{ x: windowWidth, opacity: 1 }}>
      <svelte:component this={postTypes[params.type]} id={params.id} />
    </div>
  </div>

  <div
    class="post-nav"
    in:fly={{ x: windowWidth, delay: 100 }}
    out:fly={{ x: windowWidth }}>
    <div class="post-nav-button" on:click={pop}>
      <Icon name="arrow-left" />
    </div>
    <div class="post-nav-bg">
      <svg width="140" height="127" viewBox="0 0 140 127">
        <path
          d="M140 114.283V0.220215C131.849 5.0264 123.72 11.6866 115.889
          20.6226C100.497 38.1538 91.3319 51.5483 83.6527 62.7709C78.0023
          71.0287 73.1564 78.1106 67.2265 84.7993C44.9503 109.794 21.8121 121.33
          0 126.7L125.901 126.7C131.17 122.946 135.908 118.806 140 114.283Z"
          class="post-nav-bg-1" />
        <path
          d="M140 126.7V8.98389C121.69 25.7552 103.66 52.2817 82.6743
          83.8049C66.0997 108.667 47.271 120.799 31.9561 126.7L140 126.7Z"
          class="post-nav-bg-2" />
      </svg>
    </div>
  </div>
{/if}
