<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { push } from "svelte-spa-router";
  import Metas from "../Metas.svelte";
  import { formatNumber } from "helpers";
  import { confetti } from "dom-confetti";

  export let id = 0;
  export let data = {};
  export let type = "standard";
  export let published = "";

  let confettiElm;

  onMount(() => {
    setTimeout(() => {
      if (!confettiElm) {
        return;
      }

      confetti(confettiElm, {
        angle: 90,
        spread: 20,
        startVelocity: 45,
        elementCount: 50,
        dragFriction: 0.1,
        duration: 3000,
        stagger: 0,
        width: "10px",
        height: "10px",
        colors: ["#ed454a", "#fcf8ea", "#343032", "#ffffff"]
      });
    }, 300);
  });
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.milestone-post {
  border-bottom: 1px solid #f0f0f0; }
  .milestone-post .top {
    padding: 64px 32px;
    text-align: center;
    background: #fcf8ea;
    margin-bottom: 32px;
    position: relative; }
  .milestone-post .h3 {
    color: #ed454a;
    font-weight: 900;
    margin: 8px 0; }
  .milestone-post .kicker {
    font-weight: 700; }
  .milestone-post .text {
    margin-bottom: 14px;
    width: 90%; }

.leger {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 48px;
  height: auto; }

.confetti {
  position: absolute;
  left: 50%; }

/*# sourceMappingURL=src/components/feed/MilestonePost.svelte.map */</style>

<a
  class="feed-item feed-item--std milestone-post"
  in:fade
  href="/#/"
  on:click|preventDefault={() => {
    push('/feed/post/' + id);
  }}>
  <div class="top">
    <svg width="34" height="25" viewBox="0 0 34 25" class="leger">
      <path
        d="M19.8177 20.0542C19.8203 20.0475 19.7673 19.9481 19.7503
        19.9069C19.7308 19.8602 19.7323 19.7416 19.7386 19.7147C19.7458 19.6828
        19.7332 19.6817 19.7203 19.6446C19.7158 19.6326 19.7299 19.5531 19.7269
        19.5146C19.7257 19.4967 19.6586 19.3673 19.6535 19.341C19.6484 19.3159
        19.6577 19.2542 19.6535 19.211C19.6505 19.1876 19.6457 19.1677 19.6367
        19.1458L19.3564 18.319L19.239 17.9491C19.2291 17.9187 19.0467 17.6283
        19.0359 17.5964C18.8361 17.0054 18.2104 16.0207 17.5709 15.6278C17.5499
        15.6146 17.3762 15.4092 17.3484 15.3888C17.3118 15.3625 17.2034 15.3146
        17.1869 15.2991C17.1381 15.2541 17.1019 15.1696 17.0255 15.1883C17.009
        15.1924 16.9964 15.2009 16.9865 15.214C16.9704 15.2345 16.7817 15.695
        16.7496 15.7511C16.7155 15.811 16.6705 15.8714 16.6529 15.9109C16.6262
        15.9833 16.6094 16.0467 16.5963 16.1227C16.4111 16.849 15.0178 19.1724
        14.664 19.8228L13.3422 22.2511C13.0993 22.6277 13.1247 22.2397 13.0385
        22.202C12.9205 22.15 12.9459 22.2391 12.8782 22.2864C12.7947 22.3451
        12.5889 22.384 12.5044 22.3811C12.4918 22.3805 12.3529 22.4547 12.3154
        22.4655C12.2717 22.4781 12.2334 22.4696 12.2307 22.4231C12.2295 22.4033
        12.2468 22.3472 12.2507 22.3159C12.254 22.2885 12.2438 22.2703 12.2241
        22.2508C12.1956 22.2227 12.1848 22.2405 12.1303 22.2668C12.0791 22.292
        12.0734 22.2703 12.0369 22.2213C11.9973 22.1681 11.5576 22.0705 11.5094
        22.0912C11.4462 22.1187 11.3333 22.2218 11.2857 22.1596C11.2671 22.1351
        11.2743 22.074 11.2659 22.0296C11.2596 21.9913 11.2296 21.9627 11.1904
        21.9577C11.1152 21.9542 11.0532 21.989 11.0388 21.9577C11.0149 21.9075
        11.0388 21.8788 11.0086 21.8572C10.9945 21.8464 10.896 21.8219 10.8819
        21.8116C10.8433 21.7836 10.8439 21.7465 10.8016 21.7266C10.7926 21.7231
        10.7846 21.7208 10.775 21.7205C10.6797 21.7126 10.6303 21.7398 10.5821
        21.7132C10.5267 21.6825 10.4928 21.6109 10.4712 21.5288C10.4545 21.4642
        10.4398 21.3024 10.4314 21.2881C10.4254 21.2778 10.4074 21.2702 10.3946
        21.2682C10.3634 21.2623 10.2993 21.291 10.2676 21.3041C10.2122 21.3269
        8.55578 22.2327 8.50546 22.2674C8.32904 22.3901 8.26584 22.4529 8.24517
        22.6481C8.18736 23.1919 8.46472 24.7801 9.23301 24.9884C9.33574 25.0165
        9.39864 24.9896 9.49329 24.9496L17.1025 21.3173C17.3603 21.1916 19.5798
        20.1719 19.6388 20.1521C19.6697 20.1416 19.8138 20.0624 19.8177
        20.0542ZM6.39051 18.4166C6.3932 18.4166 6.39081 18.4224 6.38781
        18.4268C6.38212 18.4347 6.37194 18.4382 6.36265 18.4411C6.3327 18.449
        2.05128 20.6631 1.69963 20.8366L0.283174 21.6156C0.115739 21.7102
        0.0165959 21.8645 0.00281775 22.0527C-0.0349225 22.6215 0.312527 23.4087
        0.680345 23.8907C0.780986 24.0225 1.01042 24.3123 1.20452
        24.3626C1.30486 24.3883 1.38393 24.3626 1.47139 24.3363C1.51602 24.3232
        5.88131 22.3282 6.43424 22.0679C7.86178 21.4549 9.06917 20.943 10.5027
        20.3435C10.5782 20.3128 10.5991 20.3002 10.6162 20.2356C10.6372 20.1565
        10.6623 19.9885 10.6797 19.9394C10.6935 19.8993 10.7483 19.8053 10.7863
        19.7311C10.8169 19.6717 10.9256 19.3617 10.9996 19.1911C11.0251 19.1324
        11.052 19.0792 11.0733 19.0217C11.1074 18.9287 11.1395 18.7713 11.17
        18.6895L12.7255 14.9049L13.4797 13.1183C13.5932 12.7671 13.5797 12.6715
        13.7834 12.337C13.8152 12.285 14.0587 11.5359 14.0671 11.4777C14.0823
        11.3731 14.1833 11.2487 14.0784 11.2241C14.0308 11.2127 13.9332 11.239
        13.8805 11.2565C13.7397 11.3074 13.6229 11.3547 13.4866 11.4161C9.36779
        13.2816 5.94212 14.9666 1.95573 17.0883C1.67956 17.2406 1.44953 17.3738
        1.18055 17.5374C0.993949 17.6411 0.891811 17.8261 0.90469
        18.035C0.956209 18.7111 1.12334 19.2756 1.44863 19.8742C1.56455 20.0741
        1.71431 20.0545 1.95243 19.9946C2.00455 19.9814 3.09692 19.5593 3.46773
        19.4091L6.32791 18.4291C6.34887 18.4233 6.36774 18.4195 6.39051
        18.4166ZM21.3485 13.7365C21.3357 13.7874 21.4776 13.922 21.5103
        13.9533C21.6085 14.0462 21.7625 14.1777 21.8044 14.2256C22.2154 14.7071
        22.4909 15.1734 22.7126 15.7613C22.715 15.7674 27.2558 15.69 27.2848
        15.683C29.5022 14.9447 31.4009 14.3974 33.6737 13.8408C33.7729 13.8055
        33.8397 13.7839 33.8738 13.7038C34.1943 12.9614 33.8501 11.4932 33.4464
        10.8531C33.3296 10.6678 33.1963 10.6696 32.9626 10.723C32.5193 10.8244
        29.7466 11.4029 29.0445 11.5595C26.5486 12.1377 24.4609 12.7075 22.0192
        13.4765C21.8496 13.5288 21.3632 13.6801 21.3485 13.7365ZM17.4238
        10.0572C17.4053 10.1048 17.4457 10.1454 17.4706 10.1667C17.5083 10.1989
        18.1427 10.5927 18.2014 10.6386C18.2445 10.6719 18.2784 10.7029 18.3149
        10.7429C18.5021 10.9471 18.5048 11.0085 18.672 11.1268C18.8529 11.2548
        18.7729 11.1984 18.9421 11.3796C18.9964 11.4377 19.2554 11.6548 19.3025
        11.7062C19.3699 11.7798 19.375 11.7936 19.4495 11.8365C19.4735 11.8502
        20.6114 12.95 20.6309 12.9757C20.7222 13.0961 20.8354 13.3216 20.9705
        13.3073C20.99 13.3056 23.8322 11.9569 23.9882 11.8757C24.7068 11.5408
        25.3163 11.2636 26.0409 10.9416C27.1977 10.4428 29.3815 9.48246 29.605
        9.39247C29.8104 9.30979 29.9183 9.25019 29.9054 9.05209C29.8685 8.48411
        29.1787 6.79651 28.6036 6.46022C28.4629 6.37753 28.3128 6.43334 28.1364
        6.48301C26.6471 6.90198 17.5571 9.71065 17.4238 10.0572ZM26.6951
        5.29561L26.8086 4.98094C26.8122 4.97246 26.8146 4.96487 26.8158
        4.9561C26.8239 4.88657 26.7828 4.8892 26.7517 4.84537C26.7265 4.80972
        26.722 4.66159 26.7184 4.6502C26.7124 4.63237 26.7044 4.61835 26.6918
        4.60462C26.6349 4.53859 26.548 4.4784 26.5249 4.42873C26.5156 4.40915
        26.4938 4.11581 26.4848 4.09682C26.4752 4.07666 26.4372 4.03692 26.4132
        4.00449C26.4069 3.99631 26.4024 3.98901 26.3982 3.97966C26.386 3.95161
        26.3761 3.87331 26.3686 3.86396C26.3593 3.85227 26.3257 3.84029 26.3183
        3.82977C26.3129 3.82247 26.3087 3.79413 26.3102 3.77397C26.3156 3.70268
        26.3278 3.66849 26.285 3.62788C26.2167 3.56389 26.1826 3.5747 26.1583
        3.49786C26.1119 3.35119 25.8624 3.27727 25.6875 3.30269C25.6554 3.30737
        25.4694 3.38596 25.4005 3.40349C25.3322 3.41489 25.2735 3.41985 25.2043
        3.41985C25.0177 3.41372 25.0165 3.41226 24.7997 3.50108C24.749 3.52182
        23.1909 3.95365 22.9441 4.0153C16.4276 5.84577 11.1006 8.01224 5.18132
        11.239C5.08667 11.2954 4.90606 11.3997 4.83836 11.4844C4.77067 11.5698
        4.75689 11.6346 4.69489 11.6764C4.67273 11.6913 4.59695 11.717 4.54123
        11.7416C4.51907 11.7512 4.34205 11.8911 4.33097 11.9075C4.31659 11.9291
        4.29353 11.9879 4.32438 12.0311C4.33216 12.0422 4.34774 12.0495 4.36122
        12.0539C4.38668 12.0624 4.41663 12.0641 4.44119 12.0735C4.46965 12.084
        4.4972 12.1056 4.52147 12.1126C4.59275 12.1325 4.60413 12.1106 4.63828
        12.155C4.66074 12.1842 4.70987 12.323 4.74851 12.3469C4.78655 12.3709
        4.84046 12.3697 4.85873 12.3957C4.86143 12.4001 4.86352 12.4039 4.86532
        12.4086C4.8761 12.4334 4.92702 12.7706 4.92702 12.8159C4.92702 12.8466
        4.90815 13.0297 4.95578 13.0631C4.99621 13.0911 5.09715 13.0683 5.14268
        13.0563C5.33408 13.0061 5.32419 12.9307 5.55633 12.7767L6.13052
        12.4609C6.16496 12.4387 6.24554 12.4103 6.25722 12.3992C6.2704 12.3843
        6.28028 12.3706 6.29076 12.3537C6.3282 12.3017 6.38062 12.2876 6.46419
        12.2625C6.53967 12.24 6.54297 12.261 6.62444 12.3212C6.79217 12.4451
        6.92935 12.5331 7.12494 12.3928C7.21241 12.3388 7.28878 12.297 7.38194
        12.2529C13.495 9.42403 18.9179 7.55733 25.4973 6.01728C25.6171 5.99537
        26.6115 5.75958 26.6355 5.74731C26.7786 5.67339 26.7415 5.51649 26.6939
        5.3333C26.6891 5.31606 26.6894 5.31226 26.6951 5.29561ZM13.8463
        3.02279C13.8463 3.56448 14.0802 4.54998 14.614 4.9038C14.6745 4.94383
        14.7434 4.96458 14.7775 5.01425C14.8276 5.08729 14.8078 5.1095 14.8743
        5.1282C14.977 5.15683 14.9893 5.12673 15.1774 5.13141C15.2059 5.13199
        15.3361 5.15245 15.3751 5.14426C15.4389 5.13112 15.4766 5.09518 15.5284
        5.1279C15.5605 5.14806 15.5512 5.16033 15.5719 5.19627C15.6054 5.255
        15.7827 5.24623 15.8316 5.24185C16.0158 5.22461 16.1736 5.17845 16.2629
        5.03353C16.2892 4.99087 16.3998 4.81352 16.5304 4.7583C16.5995 4.72909
        16.6127 4.74106 16.6424 4.71652C16.7026 4.66656 16.77 4.53011 16.7924
        4.49914C16.8119 4.47226 16.9587 4.34604 17.047 4.2657C17.4846 3.86746
        18.0723 2.63244 17.9384 1.94057C17.9097 1.79244 17.7991 1.30305 17.7147
        1.18034C17.6787 1.12804 17.6149 1.07545 17.5946 1.0176C17.5886 1.00065
        17.5865 0.98312 17.5811 0.96559C17.4996 0.68335 16.9844 0.0630643
        16.6927 0.0607269C16.6627 0.0604347 16.6364 0.0651095 16.61
        0.0607269C16.5852 0.0569286 16.4875 0.00842749 16.4567
        0.00229184C16.4261 -0.00384382 16.2913 0.00258394 16.2632
        0.0186535C16.1524 0.0776727 16.052 0.115948 15.9295 0.145458C15.8732
        0.15919 15.8516 0.153638 15.8109 0.183439C15.7732 0.210904 15.7378
        0.255315 15.7031 0.279857C15.6833 0.29359 15.5883 0.325437 15.529
        0.350856C15.004 0.575538 14.4055 1.26098 14.2108 1.76001C14.1428 1.93239
        13.8463 2.85625 13.8463 3.02279Z"
        fill="#EE342A" />
    </svg>

    <div class="kicker">Vi har nå samlet inn</div>
    <div class="h3">{formatNumber(data.amount)}</div>
    <div class="kicker">kroner til Leger uten grenser</div>
  </div>
  <div class="confetti" bind:this={confettiElm} />
  <div class="text">
    {@html data.excerpt}
  </div>
  <Metas {published} />
</a>
