<script>
  import { fade } from "svelte/transition";
  import { push } from "svelte-spa-router";
  import Metas from "../Metas.svelte";
  import EmbeddedVideo from "../EmbeddedVideo.svelte";

  export let id = 0;
  export let title = "";
  export let data = {};
  export let type = "standard";
  export let published = "";
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.video-post {
  padding-bottom: 48px; }
  .video-post .h5 {
    color: #ed454a;
    margin-bottom: 24px; }
  .video-post .text {
    margin-bottom: 14px;
    width: 90%; }

.video {
  margin-bottom: 16px; }

/*# sourceMappingURL=src/components/feed/VideoPost.svelte.map */</style>

<div class="feed-item feed-item--std video-post">
  <h2 class="h5">{title}</h2>

  {#if data.video}
    <div class="video">
      <EmbeddedVideo iframe={data.video} />
    </div>
  {/if}

  <div class="text">
    {#if data.excerpt}
      {@html data.excerpt}
    {/if}
  </div>

  <Metas {published} />
</div>
