<script>
  import Logo from "./Logo.svelte";

  let domain = window.location.hostname;
</script>
<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.desktop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #ed454a;
  color: #fff;
  display: flex;
  z-index: 13337; }

.content {
  margin: auto;
  max-width: 600px;
  text-align: center;
  padding: 0 32px; }

.logo {
  max-width: 200px;
  margin: 0 auto 32px auto; }

p {
  margin-bottom: 32px; }

/*# sourceMappingURL=src/components/DesktopNotification.svelte.map */</style>

<div class="desktop">

  <div class="content">
    <div class="logo">
      <Logo />
    </div>
    <p class="h5">
      Denne webappen er lagd for mobil. Besøk denne siden på mobilen for å fortsette:
    </p>

    <p class="h5">{domain}</p>
  </div>

</div>
