<script>
  function reload() {
    location.reload(true);
  }
</script>

<style>/**
 * This file is included in /postcss.js to make variables an mixins available
 * to svelte components without having to import them everywhere.
 *
 * No styles here please, they'll be removed by svelte.
 */
.notice {
  background: #ed454a;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  font-size: 0.75rem;
  line-height: 1.5;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.15);
  text-align: center; }

p {
  max-width: 260px;
  margin: auto; }

.button {
  display: inline-block;
  margin-top: 16px; }

/*# sourceMappingURL=src/components/RefreshNotice.svelte.map */</style>

<div class="notice" on:click={reload}>
  <p>En ny versjon av appen er tilgjengelig, trykk her for å aktivere.</p>
    <span class="button button--ghost">Aktiver</span>
</div>
